<template>
  <div class="card" :class="[element.customClasses]">
    <div
      class="card-header"
      :class="[`bg-${element.color}`, element.labelClassNames]"
      :style="{
        background: String.isNullOrWhiteSpace(element.linearGradient)
          ? ''
          : element.linearGradient + '!important',
      }"
      @click="editItem.isCollapsed = !editItem.isCollapsed"
    >
      <span
        :class="`${element.labelClassNames}`"
        :style="{
          color: String.isNullOrWhiteSpace(element.fontColor)
            ? ''
            : element.fontColor + '!important',
        }"
        >{{ getTitle(element) }}</span
      >
    </div>
    <div class="card-body" :class="{ collapse: editItem.isCollapsed }">
      <DesignLayoutChild
        :customObjectItems="customObjectItems"
        :items="element.items"
        v-if="element.container == true"
      />
    </div>
  </div>
</template>

<script>
import DesignLayoutChild from "../DesignLayoutChild.vue";
import panelTextColor from "../helpers/PanelTextColorPicker";

export default {
  props: ["element", "customObjectItems"],
  name: "PanelItem",
  components: {
    DesignLayoutChild,
  },
  data() {
    return {
      editItem: this.element,
    };
  },
  methods: {
    getTitle(element) {
      if (this.$store.state.isMultiLanguage) {
        var localizationValue =
          this.$root.getLocalizationValueForPageDesigner(element);
        if (localizationValue) {
          return localizationValue.value;
        } else {
          return element.title;
        }
      } else {
        return element.title;
      }
    },
  },
  computed: {
    textColor() {
      return panelTextColor.get(this.element);
    },
  },
};
</script>

<style scoped>
.card-header {
  padding: 1rem 1.5rem !important;
}
</style>
