<template>
  <template
    v-if="!String.isNullOrWhiteSpace(currentField) && element.type == 'field'"
  >
    <div
      class="form-group page-item"
      :class="{
        hidden: element.isHidden,
      }"
      data-istable="false"
      :data-tableid="currentField.tablePublicId"
      :data-fieldtype="currentField.fieldType"
      :data-inputtype="currentField.inputHtmlType"
      :data-maxlength="currentField.length"
      :data-islivelookup="currentField.isDataReadFromMainTable"
      :data-iscalculatedonlyoninsert="currentField.isCalculatedOnlyOnInsert"
      :data-decimalplaces="currentField.decimalPlaces"
      :data-inputmaskpattern="currentField.inputMaskPattern"
      :data-controllingpublicid="currentField.controllingPublicId"
      :data-lookupobjectid="currentField.lookupObjectPublicId"
      :data-lookupobjectname="currentField.lookupObjectName"
      :data-lookupobjecturl="currentField.lookupObjectKey"
      :data-lookupfieldids="currentField.lookupFieldPublicIds"
      :data-selecteditempublicids="currentField.defaultValuePublicIds"
      :data-organizationalunitname="currentField.organizationalUnitName"
      :data-organizationalunitfiltertype="
        currentField.organizationalUnitFilterType
      "
      :data-organizationalunitgrouppublicids="
        currentField.organizationalUnitGroupPublicIds
      "
      :data-organizationalunitdepth="currentField.organizationalUnitDepth"
      :data-organizationalunitincludeitself="
        currentField.organizationalUnitIncludeItself
      "
      :data-ismultiplevalue="currentField.isMultipleValue"
      :data-isradio="currentField.isRadio"
      :data-isrequired="currentField.isRequired"
      :data-pagelayoutelementisrequired="element.isRequired"
      :data-pagelayoutelementisdisabled="element.isDisabled"
      :data-pagelayoutelementishidden="element.isHidden"
      :data-isunique="currentField.isUnique"
      :data-isactive="currentField.isActive"
      :data-isdisabled="
        !currentField.isActive ||
        element.isDisabled ||
        currentField.fieldType.includes('Formula') ||
        currentField.fieldType.includes('AutoId') ||
        ($route.meta.isEditPage && !currentField.isEditable)
      "
      :data-isclientcalculate="currentField.isClientCalculate"
      :data-iscalculateonclientchange="currentField.isCalculateOnClientChange"
      :data-ischeckunchanged="currentField.isCheckUnchanged"
      :data-isuniquecheckclientcalculate="
        currentField.isUniqueCheckClientCalculate
      "
      :data-istablerollup="
        currentField.fieldType.includes('Rollup') ? true : false
      "
      :data-isnoteditable="!currentField.isEditable"
      :data-rolluptable="currentField.tablePublicId"
      :data-rollupfield="currentField.rollupFieldPublicId"
      :data-rollupformula="currentField.aggregateFunction"
      :data-calculatetriggerfieldpublicids="
        currentField.calculateTriggerFieldPublicIds
      "
      :data-isthousandseparator="currentField.isThousandSeparator"
      :data-formulaname="currentField.formulaName"
      :data-formulatext="currentField.formulaText"
      :data-predefinedpublicid="currentField.predefinedPublicId"
      :data-predefineddependencypublicid="
        currentField.predefinedDependencyPublicId
      "
      :data-viewfilterpublicid="currentField.viewFilterPublicId"
      :data-publicid="currentField.publicId"
      :data-value="
        $route.meta.isDetailPage ? currentFieldValue : currentField.defaultValue
      "
      :data-property="currentField.property"
      :data-oncleardefaultvalueid="currentFieldValueId"
      :data-oncleardefaultvalue="currentFieldValue"
      :data-publicids="$route.meta.isDetailPage ? currentFieldValueId : ''"
    >
      <div class="d-flex justify-content-between align-items-center">
        <label
          @dblclick="toggleEditMode"
          data-bs-toggle="tooltip"
          data-bs-html="true"
          data-bs-placement="top"
          :data-bs-original-title="
            $route.meta.isDetailPage &&
            !isPageItemEditable &&
            isCellEditingEnabled
              ? pageItemNoEditableMessage
              : ''
          "
          :for="
            currentField.fieldType == 'Checkbox' && $route.meta.isDetailPage
              ? ''
              : element.id
          "
          class="form-label fs-6 fw-bolder"
          :class="`${
            element.labelClassNames !== undefined ? element.labelClassNames : ''
          } ${
            isRequired &&
            (onEditedPageItem ||
              $route.meta.isNewPage ||
              $route.meta.isEditPage ||
              $route.meta.isCalendarPage ||
              $route.meta.isTreePage)
              ? 'required'
              : ''
          } ${
            isDetailPage &&
            isPageItemEditable &&
            !onEditedPageItem &&
            isCellEditingEnabled
              ? 'cursor-pointer'
              : ''
          } ${isDetailPage ? 'no-selection' : ''}`"
        >
          <i
            class="fa fa-ban"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="
              this.$t(
                'BaseModelFields.FieldWasSetToPassive',
                {},
                { locale: this.$store.state.activeLang }
              )
            "
            v-if="!currentField.isActive"
          ></i>

          {{ labelText }}

          <template
            v-if="
              currentField.fieldType == 'SelectList' &&
              currentField.isRadio &&
              !isDetailPage
            "
          >
            <template v-if="$isMobile()">
              <button
                ref="radioSelectlistClear"
                type="button"
                class="btn btn-danger btn-xs ms-2"
                :class="{
                  hidden: String.isNullOrWhiteSpace(currentFieldValue),
                }"
                :id="
                  String.format(
                    'sx-radio-select-clear-{0}',
                    currentField.publicId
                  )
                "
                @click="radioSelectlistClear(currentField.publicId)"
              >
                {{
                  $t(
                    "BaseModelFields.Clear",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </button>
            </template>
            <template v-else>
              <i
                ref="radioSelectlistClear"
                class="fa fa-times float-end text-danger cursor-pointer ms-1 mt-1"
                :class="{
                  hidden: String.isNullOrWhiteSpace(currentFieldValue),
                }"
                :id="
                  String.format(
                    'sx-radio-select-clear-{0}',
                    currentField.publicId
                  )
                "
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                :title="
                  $t(
                    'BaseModelFields.Clear',
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                "
                @click="radioSelectlistClear(currentField.publicId)"
              ></i>
            </template>
          </template>

          <i
            class="bi bi-question-circle-fill ms-1"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            :title="currentField.helpText"
            v-if="
              !String.isNullOrWhiteSpace(currentField.helpText) && !isDetailPage
            "
          ></i>
          <a
            v-if="
              currentField.fieldType == 'Lookup' &&
              currentField.isPlusButtonActive &&
              !isDetailPage
            "
            class="cursor-pointer ms-1"
            @click="openNewTab"
            :data-lookupObjectPublicId="currentField.lookupObjectPublicId"
            :data-href="
              String.format('#/set/new/{0}', currentField.lookupObjectKey)
            "
          >
            <i class="fas fa-plus text-success"></i>
          </a>
          <a
            v-if="
              currentField.fieldType == 'Lookup' &&
              currentField.isViewingFilterButtonActive &&
              !currentField.isMultipleValue &&
              !isDetailPage
            "
            target="_blank"
            :id="String.format('open_detail_{0}', currentField.publicId)"
            class="hidden ms-2"
          >
            <i class="fas fa-external-link-alt text-primary"></i>
          </a>
        </label>

        <div class="edit-mode-action-buttons hidden" v-if="isDetailPage">
          <div class="d-flex">
            <button
              type="button"
              class="btn btn-success btn-icon btn-edit-mode-save mb-2 me-1"
              @click="detailEditSave"
            >
              <span>
                <i class="fa fa-save"></i>
              </span>
            </button>
            <button
              type="button"
              class="btn btn-danger btn-icon btn-edit-mode-cancel mb-2"
              @click="detailEditCancel"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <form
        v-if="isDetailPage"
        @submit="detailEditSave"
        class="detail-edit-forms"
        method="post"
        action="#"
        :id="editModeFormId"
      >
        <TextBox
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'Text' ||
            currentField.fieldType == 'FormulaString' ||
            currentField.fieldType == 'Url' ||
            currentField.fieldType == 'AutoId' ||
            currentField.fieldType == 'Email'
          "
        />
        <PercentInput
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'Percent'"
        />
        <NumericTextBox
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'Number' ||
            currentField.fieldType == 'DoublePrecision' ||
            currentField.fieldType == 'LongInteger' ||
            currentField.fieldType == 'FormulaNumber' ||
            currentField.fieldType == 'Rollup'
          "
        />
        <Checkbox
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'Checkbox'"
        />
        <TextArea
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'TextArea' ||
            currentField.fieldType == 'FormulaTextArea'
          "
        />
        <DatePicker
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'Date' ||
            currentField.fieldType == 'FormulaDate' ||
            (isNewPage && currentField.fieldType == 'RollupDate')
          "
        />
        <DateTimePicker
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'DateTime' ||
            currentField.fieldType == 'FormulaDateTime' ||
            (isNewPage && currentField.fieldType == 'RollupDateTime')
          "
        />
        <TimePicker
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'Time'"
        />
        <Spatial
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'Spatial' ||
            currentField.fieldType == 'FormulaSpatial'
          "
        />
        <SelectList
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'SelectList' && !currentField.isRadio"
        />
        <RadioSelectList
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-else-if="
            currentField.fieldType == 'SelectList' && currentField.isRadio
          "
        />
        <PagedSelectList
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'Lookup' ||
            currentField.fieldType == 'OrganizationalUnit' ||
            currentField.fieldType == 'FormulaOrganizationalUnit' ||
            currentField.fieldType == 'Predefined'
          "
        />
        <FileInput
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'File' ||
            currentField.fieldType == 'Image'
          "
        />
        <PhoneInput
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'Phone'"
        />
        <HtmlEditor
          ref="customObjectItem"
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="currentField.fieldType == 'Html'"
        />
        <ReadOnly
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          :isCellEdit="isCellEditPageItem"
          v-if="
            currentField.fieldType == 'ReadOnly' ||
            (!isNewPage &&
              (currentField.fieldType == 'RollupDate' ||
                currentField.fieldType == 'RollupDateTime'))
          "
        />
      </form>
      <template v-else>
        <TextBox
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'Text' ||
            currentField.fieldType == 'FormulaString' ||
            currentField.fieldType == 'Url' ||
            currentField.fieldType == 'AutoId' ||
            currentField.fieldType == 'Email'
          "
        />
        <PercentInput
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="currentField.fieldType == 'Percent'"
        />
        <NumericTextBox
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'Number' ||
            currentField.fieldType == 'DoublePrecision' ||
            currentField.fieldType == 'LongInteger' ||
            currentField.fieldType == 'FormulaNumber' ||
            currentField.fieldType == 'Rollup'
          "
        />
        <Checkbox
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="currentField.fieldType == 'Checkbox'"
        />
        <TextArea
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'TextArea' ||
            currentField.fieldType == 'FormulaTextArea'
          "
        />
        <DatePicker
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'Date' ||
            currentField.fieldType == 'FormulaDate' ||
            (isNewPage && currentField.fieldType == 'RollupDate')
          "
        />
        <DateTimePicker
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'DateTime' ||
            currentField.fieldType == 'FormulaDateTime' ||
            (isNewPage && currentField.fieldType == 'RollupDateTime')
          "
        />
        <TimePicker
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="currentField.fieldType == 'Time'"
        />
        <Spatial
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'Spatial' ||
            currentField.fieldType == 'FormulaSpatial'
          "
        />
        <SelectList
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          v-if="currentField.fieldType == 'SelectList' && !currentField.isRadio"
        />
        <RadioSelectList
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          v-else-if="
            currentField.fieldType == 'SelectList' && currentField.isRadio
          "
        />
        <PagedSelectList
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'Lookup' ||
            currentField.fieldType == 'OrganizationalUnit' ||
            currentField.fieldType == 'FormulaOrganizationalUnit' ||
            currentField.fieldType == 'Predefined'
          "
        />
        <FileInput
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :valueId="currentFieldValueId"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'File' ||
            currentField.fieldType == 'Image'
          "
        />
        <PhoneInput
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="currentField.fieldType == 'Phone'"
        />
        <HtmlEditor
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="currentField.fieldType == 'Html'"
        />
        <ReadOnly
          :element="element"
          :field="currentField"
          :value="currentFieldValue"
          :crudType="page.name"
          v-if="
            currentField.fieldType == 'ReadOnly' ||
            (!isNewPage &&
              (currentField.fieldType == 'RollupDate' ||
                currentField.fieldType == 'RollupDateTime'))
          "
        />
      </template>
    </div>
  </template>
  <template v-else-if="element.type == 'lookup'">
    <template v-if="isNewPage && currentTable.isShowInNewPage">
      <LocalTable
        :table="currentTable"
        :tableValues="currentTableValues"
        :tableSortOptions="
          this.$root.gridSortOption('LookupRelation', currentTable)
        "
        :element="element"
        :buttons="buttons"
        :crudType="page.name"
      />
    </template>
    <template v-else-if="isDetailPage">
      <Table
        :table="currentTable"
        :tableValues="currentTableValues"
        :tableSortOptions="
          this.$root.gridSortOption('LookupRelation', currentTable)
        "
        :element="element"
        :buttons="buttons"
        :crudType="page.name"
      />
    </template>
    <template v-else-if="isEditPage && currentTable.isShowInEditPage">
      <Table
        :table="currentTable"
        :tableValues="currentTableValues"
        :tableSortOptions="
          this.$root.gridSortOption('LookupRelation', currentTable)
        "
        :element="element"
        :buttons="buttons"
        :crudType="page.name"
      />
    </template>
  </template>
  <template v-else-if="element.type == 'button'">
    <Button :element="element" :button="currentButton" />
  </template>
</template>

<script>
import $ from "jquery";

import TextBox from "../form-items/TextBox.vue";
import NumericTextBox from "../form-items/NumericTextBox.vue";
import Checkbox from "../form-items/Checkbox.vue";
import TextArea from "../form-items/TextArea.vue";
import DateTimePicker from "@/components/devexpress/dateboxes/DateTimePicker.vue";
import DatePicker from "@/components/devexpress/dateboxes/DatePicker.vue";
import TimePicker from "@/components/devexpress/dateboxes/TimePicker.vue";
import SelectList from "../form-items/SelectList.vue";
import RadioSelectList from "../form-items/RadioSelectList.vue";
import PagedSelectList from "../form-items/PagedSelectList.vue";
import FileInput from "../form-items/FileInput.vue";
import PhoneInput from "../form-items/PhoneInput.vue";
import HtmlEditor from "../form-items/HtmlEditor.vue";
import PercentInput from "../form-items/PercentInput.vue";
import Spatial from "../form-items/Spatial.vue";
import ReadOnly from "../form-items/ReadOnly.vue";

import Table from "../form-items/Table.vue";
import LocalTable from "../form-items/LocalTable.vue";

import Button from "../form-items/Button.vue";
import { createToast } from "mosha-vue-toastify";

import { showModal } from "@/core/helpers/dom";

// import { onUnmounted } from "vue";
export default {
  name: "CustomObjectItem",
  props: ["element", "data"],
  components: {
    TextBox,
    Checkbox,
    TextArea,
    DatePicker,
    DateTimePicker,
    TimePicker,
    SelectList,
    PagedSelectList,
    RadioSelectList,
    NumericTextBox,
    FileInput,
    PhoneInput,
    HtmlEditor,
    PercentInput,
    Button,
    Table,
    LocalTable,
    Spatial,
    ReadOnly,
  },
  data() {
    return {
      isDetailPage: this.$route.meta.isDetailPage,
      isNewPage: this.$route.meta.isNewPage,
      isEditPage: this.$route.meta.isEditPage,
      pageData: {},
      currentField: {},
      currentFieldValue: "",
      currentFieldValueId: "",
      currentTable: {},
      currentTableValues: [],
      currentButton: {},
      buttons: [],
      editModeErrors: [],
      editModeWarnings: [],
      editModeInformations: [],
      businessRuleErrors: [],
      isRequired: false,
      selectFieldTypes: [
        "SelectList",
        "Lookup",
        "Predefined",
        "OrganizationalUnit",
      ],
      onEditedPageItem: false,
      isCellEditPageItem: false,
      isPageItemEditable: false,
      pageItemNoEditableMessage: null,
      editModeSubmitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></span>'
      ),
      editModeDisabledButtonClasses:
        ".btn-edit-mode-cancel, .btn-edit-mode-save",
      editModeFormId: `detailEditForm_${this.element.id}`,
      isCellEditingEnabled: false,
      labelText: "",
      customObjectItemPermissions: {},
    };
  },
  methods: {
    closeOpenedEditMode($event) {
      //only cancel event
      if (!String.isNullOrWhiteSpace($event)) {
        var target = $($event.target),
          pageItem = target.closest(".page-item");

        var oldValue = this.$root.getPageItemFormValue(pageItem, true);
        this.$refs.customObjectItem.setValue(
          false,
          oldValue.value,
          oldValue.text
        );
      }

      //open edit mode page items closed
      var pageItems = $("body")
        .find(".page-item-edit-mode")
        .filter(function (f) {
          return !$(f).hasClass("hidden");
        });
      if (pageItems.length > 0) {
        pageItems.each(function (i, v) {
          var item = $(v);
          item.toggleClass("page-item-edit-mode");
          item
            .find(
              ".view-edit-mode, .view-detail-mode, .edit-mode-action-buttons"
            )
            .toggleClass("hidden");
        });
      }
    },
    detailEditCancel($event) {
      this.closeOpenedEditMode($event);
    },
    detailEditSave($event, isForcingSave = false) {
      $event.preventDefault();
      if (!this.isPageItemEditable || !this.isCellEditingEnabled) return;

      var target = $($event.target),
        pageItem = target.closest(".page-item"),
        pageItemFieldLabelText = pageItem.find("label:first").text().trim(),
        form = $("#" + this.editModeFormId);
      form.addClass("was-validated");

      if (!form[0].checkValidity() || !this.$root.forceFormValidation(form)) {
        return;
      }

      var button = $(String.format(".btn-edit-mode-save")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.editModeDisabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.editModeSubmitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      var model = {
        customObjectPublicId: $("#CustomObjectPublicId").val(),
        customObjectKey: $("#CustomObjectKey").val(),
        pageLayoutId: $("#LayoutPublicId").val(),
        recordPublicId: $("#RecordPublicId").val(),
        values: [],
      };

      if (isForcingSave) {
        model.isForcingSave = true;
      }

      var value = this.$root.getPageItemFormValue(pageItem),
        oldValue = this.$root.getPageItemFormValue(pageItem, true);
      model.values.push({
        key: this.currentField.formulaName,
        value: value.value.toString(),
      });

      this.editModeErrors = [];
      this.editModeInformations = [];
      this.editModeWarnings = [];
      this.businessRuleErrors = [];
      this.$appAxios
        .post("/rws-SetController-Edit", model)
        .then((response) => {
          var result = response.data;

          var validationModalOpen = false,
            brResponse = result.businessRuleResponse,
            brActionResponses = brResponse.businessRuleResponses,
            businessRuleNotificationType = brResponse.notificationType;
          if (
            brActionResponses.length > 0 &&
            businessRuleNotificationType !== "Tooltip"
          ) {
            validationModalOpen = true;
            this.businessRuleErrors = brActionResponses;
          }

          firstSpan.show();
          loadingBlock.hide();
          disabledButtons.prop("disabled", false);

          if (result.isOk) {
            this.onEditedPageItem = !this.onEditedPageItem;
            this.isCellEditPageItem = false;
            pageItem.toggleClass("page-item-edit-mode");
            pageItem
              .find(
                ".view-edit-mode, .view-detail-mode, .edit-mode-action-buttons"
              )
              .toggleClass("hidden");

            //default value trigger
            this.$root.calculateTriggerField(this.currentField);

            if (
              this.$root.fieldTypes.select.includes(this.currentField.fieldType)
            ) {
              this.$root.select2PagedSelectlistSelection(
                this.currentField,
                value.value
              );
            }

            createToast(
              pageItemFieldLabelText +
                this.$t(
                  "FieldWarnings.PageItemUptadeSuccessMessage",
                  {},
                  { locale: this.$store.state.activeLang }
                ),
              {
                showIcon: true,
                position: "top-right",
                type: "success",
                transition: "zoom",
              }
            );
          } else {
            validationModalOpen = true;
            if (!String.isNullOrWhiteSpace(result.msg)) {
              this.editModeErrors.push(result.msg);
            }

            if (!String.isNullOrWhiteSpace(result.message)) {
              this.editModeErrors.push(result.message);
            }
          }

          if (!String.isNullOrWhiteSpace(result.validations)) {
            result.validations.forEach((validation) => {
              var errorMessage = validation.message;
              if (!String.isNullOrWhiteSpace(validation.fieldName)) {
                errorMessage = `${validation.fieldName}: ${validation.message}`;
              }
              if (
                validation.validationType &&
                validation.validationType.value == 2
              ) {
                this.editModeWarnings.push({
                  message: errorMessage,
                  model: model,
                  self: this,
                  component: "CustomObjectItem",
                  event: $event,
                });
              } else if (
                validation.validationType &&
                validation.validationType.value == 3
              ) {
                this.editModeInformations.push({
                  fieldName: validation.fieldName,
                  message: validation.message,
                });
              } else {
                this.editModeErrors.push(errorMessage);
              }
            });

            // createToast(
            //   this.editModeErrors.length > 0
            //     ? this.editModeErrors[0]
            //     : this.$t(
            //         "BaseModelFields.ErrorMessage",
            //         {},
            //         { locale: this.$store.state.activeLang }
            //       ),
            //   {
            //     showIcon: true,
            //     position: "top-right",
            //     type: "danger",
            //     transition: "zoom",
            //   }
            // );
          }

          if (this.editModeWarnings.length == 0) {
            this.$refs.customObjectItem.setValue(
              result.isOk,
              result.isOk ? value.value : oldValue.value,
              result.isOk ? value.text : oldValue.text
            );
          }

          if (validationModalOpen || this.editModeInformations.length > 0) {
            this.$root.editModeErrors = this.editModeErrors;
            this.$root.editModeWarnings = this.editModeWarnings;
            this.$root.editModeInformations = this.editModeInformations;
            this.$root.editModeBusinessRuleResponse = brResponse;
            showModal(
              document.getElementById("modalInlineError_detailPageEditCells")
            );
          }
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    toggleEditMode($event) {
      if (!this.isPageItemEditable || !this.isCellEditingEnabled) return;

      this.onEditedPageItem = !this.onEditedPageItem;
      this.isCellEditPageItem = true;
      this.closeOpenedEditMode();

      var target = $($event.target);
      target.closest(".page-item").toggleClass("page-item-edit-mode");
      target
        .closest(".page-item")
        .find(".view-edit-mode, .view-detail-mode, .edit-mode-action-buttons")
        .toggleClass("hidden");

      if (
        this.currentField.fieldType == "SelectList" &&
        this.currentField.isRadio
      ) {
        var el = this.$refs.customObjectItem;
        if (el && !el.getSelectlistItemsRequestEnd) {
          el.getSelectlistItems();
        }
      }
    },
    openNewTab(event) {
      localStorage.removeItem("NewLookupTabData");
      localStorage.removeItem("LookupInfo");

      var $this = $(event.currentTarget),
        self = this,
        lookupTabData = "NewLookupTabData",
        pageItem = $this.closest(".form-group");

      if (!String.isNullOrWhiteSpace(pageItem.data("controllingpublicid"))) {
        var parentElement = $(
            String.format("#{0}", pageItem.data("controllingpublicid"))
          ),
          parentData = parentElement.select2("data");

        //parent element no value
        if (String.isNullOrWhiteSpace(parentElement.val())) return;

        var item = parentElement.closest(".form-group");

        localStorage.setItem(
          "LookupInfo",
          JSON.stringify({
            coId: item.data("lookupobjectid"),
            rId: parentData[0].id,
            val: parentData[0].text,
            fId: "",
          })
        );
      }

      localStorage.setItem(
        lookupTabData,
        JSON.stringify({
          id: $this.data("id"),
          fieldIds: pageItem.data("lookupfieldids"),
          data: null,
          fData: {},
          isMultiple: pageItem.data("ismultiplevalue"),
        })
      );
      window.open($this.data("href"), "_blank");

      var timer = setInterval(function () {
        var newLookupTabData = localStorage.getItem(lookupTabData),
          obj = JSON.parse(newLookupTabData);

        if (obj && obj.data) {
          var pId = pageItem.data("publicid"),
            pIdSelector = "#" + pId;
          var selectedData = $(pIdSelector).select2("data");
          if (obj.isMultiple) {
            if (!selectedData) selectedData = [];
            selectedData.push(obj.data);
          } else {
            selectedData = obj.data;
          }

          var id = selectedData.id,
            text = selectedData.text;
          if (self.currentField.isMultipleValue) {
            id = selectedData
              .map(function (x) {
                return x.id;
              })
              .join(self.$systemSeparator);

            text = selectedData
              .map(function (x) {
                return x.text;
              })
              .join(self.$systemSeparator);
          }

          self.$root.select2SetValue(
            $(pIdSelector),
            self.currentField.isMultipleValue,
            id,
            text
          );

          $(pIdSelector)
            .parents(".select2-invalid")
            .removeClass("select2-invalid")
            .addClass("select2-valid");

          self.$root.select2PagedSelectlistSelection(self.currentField, id);
          self.$root.calculateTriggerField(self.currentField);

          if (!self.currentField.isMultipleValue) {
            var detailIcon = document.getElementById(
              `open_detail_${self.currentField.publicId}`
            );
            if (detailIcon) {
              detailIcon.classList.remove("hidden");
              detailIcon.setAttribute(
                "href",
                `#/set/detail/${self.currentField.lookupObjectKey}/${id}`
              );
            }
          }

          localStorage.removeItem(lookupTabData);
          clearInterval(timer);
        }
      }, 1500);
    },
    radioSelectlistClear(publicId) {
      var items = $(`[name="${publicId}"]`),
        clearButton = $(`#sx-radio-select-clear-${this.currentField.publicId}`);

      items.prop("checked", false);
      clearButton.addClass("hidden");

      if (
        this.currentField.isMultipleValue &&
        (this.currentField.isRequired || this.element.isRequired)
      ) {
        items.attr("required", "required");
      }

      if (!this.$route.meta.isDetailPage) {
        this.$root.calculateTriggerField(this.currentField);
      }
    },
  },
  computed: {
    page() {
      let parent = this.$parent;
      while (
        parent &&
        parent.$options.name !== "NEW" &&
        parent.$options.name !== "EDIT" &&
        parent.$options.name !== "LIST" &&
        parent.$options.name !== "DETAIL" &&
        parent.$options.name !== "CALENDAR" &&
        parent.$options.name !== "HISTORY" &&
        parent.$options.name !== "TREE" &&
        parent.$options.name !== "MAP" &&
        parent.$options.name !== "KANBAN"
      ) {
        parent = parent.$parent;
      }
      return { name: parent.$options.name, data: parent.pageData };
    },
  },
  mounted() {
    //
  },
  created() {
    this.pageData = this.page.data;
    this.isCellEditingEnabled = this.pageData.layout.isCellEditingEnabled;

    this.currentField = this.page.data.fields
      .find((f) => f.isMainObject)
      .fieldModels.find((f) => f.publicId === this.element.id);

    if (
      this.element.type === "field" &&
      !String.isNullOrWhiteSpace(this.currentField)
    ) {
      if (!this.currentField.isActive) {
        this.currentField.isRequired = false;
      }

      this.customObjectItemPermissions =
        this.$store.getters.getPermissionsOfCustomObjects({
          customObjectPublicId: this.currentField.customObjectPublicId,
        });

      this.labelText = this.currentField.name;
      if (this.$store.state.isMultiLanguage) {
        var fieldLocalizationValue =
          this.$store.getters.getLocalizationValuesByParameters({
            parentId: this.currentField.customObjectPublicId,
            itemFormulaName: this.currentField.formulaName,
            itemTypeId: 2,
          });
        if (fieldLocalizationValue) {
          this.labelText = fieldLocalizationValue.value;
        }
      }

      this.isPageItemEditable =
        this.$route.meta.isDetailPage &&
        !this.element.isDisabled &&
        this.currentField.isEditable &&
        !this.currentField.fieldType.includes("Formula") &&
        !this.currentField.fieldType.includes("AutoId") &&
        this.currentField.fieldType != "ReadOnly" &&
        this.currentField.fieldType != "Rollup";

      if (!this.isPageItemEditable) {
        this.pageItemNoEditableMessage = this.$t(
          "FieldWarnings.NoEditableField",
          {},
          { locale: this.$store.state.activeLang }
        ).replaceAll("FIELD_TYPE", this.currentField.fieldType);
        if (this.element.isDisabled) {
          this.pageItemNoEditableMessage = this.$t(
            "FieldWarnings.NoEditableFieldForPageLayout",
            {},
            { locale: this.$store.state.activeLang }
          );
        } else if (!this.currentField.isEditable) {
          this.pageItemNoEditableMessage = this.$t(
            "FieldWarnings.NoEditableFieldForUpdatePage",
            {},
            { locale: this.$store.state.activeLang }
          );
        }
      }

      this.isRequired =
        (this.currentField.isRequired || this.element.isRequired) &&
        this.currentField.fieldType !== "AutoId" &&
        this.currentField.formulaName !== "ISACTIVE";
      // && !this.currentField.fieldType.includes("Formula");

      if (this.$route.meta.isCalendarPage) {
        if (this.page.data.values.length > 0) {
          var fieldValue = this.page.data.values.find(
            (f) => f.key === this.currentField.formulaName
          );

          if (!String.isNullOrWhiteSpace(fieldValue)) {
            fieldValue = fieldValue.value;
            var fieldValueIsMultiple =
                fieldValue.split(this.$systemSeparator).length > 2,
              isSeparator =
                fieldValue.includes(this.$selectSeparator) &&
                !this.currentField.fieldType.includes("Date") &&
                (this.currentField.inputHtmlType.includes("select") ||
                  this.currentField.fieldType.includes("Formula"));
            if (isSeparator) {
              var splittedValue = fieldValue.split(this.$selectSeparator);
              if (fieldValueIsMultiple) {
                this.currentFieldValueId = splittedValue[0].split(
                  this.$systemSeparator
                )[0];
                this.currentFieldValue = splittedValue[1].split(
                  this.$systemSeparator
                )[0];
              } else {
                this.currentFieldValue = splittedValue[1];
                this.currentFieldValueId = splittedValue[0];
              }
            } else {
              this.currentFieldValue = fieldValue;
            }
          }

          // if (!this.currentField.fieldType.includes("Formula")) {
          //   if (String.isNullOrWhiteSpace(this.currentFieldValue)) {
          //     this.currentFieldValue = this.currentField.defaultValue;
          //   }

          //   if (String.isNullOrWhiteSpace(this.currentFieldValueId)) {
          //     this.currentFieldValueId =
          //       this.currentField.defaultValuePublicIds;
          //   }
          // }
        }

        if (!String.isNullOrWhiteSpace(this.page.data.responseValues)) {
          var responseFieldValue =
            this.page.data.responseValues[this.currentField.formulaName];
          if (String.isNullOrWhiteSpace(responseFieldValue)) return;

          this.currentFieldValue = responseFieldValue.value;
          this.currentFieldValueId = responseFieldValue.publicIdValue;
        }
      } else if (this.$route.meta.isNewPage || this.$route.meta.isTreePage) {
        //default values
        if (this.page.data.values.length > 0) {
          fieldValue = this.page.data.values.find(
            (f) => f.key === this.currentField.formulaName
          );

          if (!String.isNullOrWhiteSpace(fieldValue)) {
            fieldValue = fieldValue.value;

            if (
              this.$root.fieldTypes.select.includes(this.currentField.fieldType)
            ) {
              this.currentFieldValue = fieldValue.split(
                this.$selectSeparator
              )[1];
              this.currentFieldValueId = fieldValue.split(
                this.$selectSeparator
              )[0];
            } else {
              this.currentFieldValue = fieldValue;
            }
          }
        }
      } else {
        if (!String.isNullOrWhiteSpace(this.page.data.responseValues)) {
          responseFieldValue =
            this.page.data.responseValues[this.currentField.formulaName];
          if (String.isNullOrWhiteSpace(responseFieldValue)) return;

          this.currentFieldValue = responseFieldValue.value;
          this.currentFieldValueId = responseFieldValue.publicIdValue;
        }
      }
    }

    if (this.element.type === "lookup") {
      this.currentTable = this.page.data.lookupRelations.find(
        (f) => f.publicId === this.element.id
      );
      this.currentTableValues = this.page.data.tableValues;
      if (this.$store.state.isMultiLanguage && this.currentTable) {
        var lookupRelationLocalizationValue =
          this.$store.getters.getLocalizationValuesByParameters({
            parentId: this.currentTable.customObjectPublicId,
            itemFormulaName: this.currentTable.formulaName,
            itemTypeId: 9,
          });
        if (lookupRelationLocalizationValue) {
          this.currentTable.name = lookupRelationLocalizationValue.value;
        }
      }
    }

    if (this.element.type === "button") {
      this.currentButton = this.page.data.buttons.find(
        (f) => f.publicId === this.element.id
      );

      if (this.$store.state.isMultiLanguage && this.currentButton) {
        var buttonLocalizationValue =
          this.$store.getters.getLocalizationValuesByParameters({
            parentId: this.currentButton.customObjectPublicId,
            itemFormulaName: this.currentButton.formulaName,
            itemTypeId: 10,
          });
        if (buttonLocalizationValue) {
          this.currentButton.name = buttonLocalizationValue.value;
        }
      }
    }

    this.buttons = this.page.data.buttons;
  },
};
</script>
