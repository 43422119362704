<template>
  <div
    :class="{ hidden: !isTableShow || tableNoPermitted }"
    :data-panelid="element.id"
    :data-id="table.publicId"
    :data-formulaname="table.formulaName"
    :data-customobjectid="table.customObjectPublicId"
    :data-lookupobjectid="table.lookupObjectPublicId"
    :data-key="table.lookupObjectUrlName"
    :data-fieldid="table.fieldPublicId"
    :data-fieldformulaname="table.fieldFormulaName"
    :data-parentrecordid="$route.params.id"
    data-lookupval=""
    :data-pagecount="table.pageItemCount"
    :data-viewfilterid="table.viewFilterPublicId"
    :data-viewfilterformulaname="table.viewFilterFormulaName"
    :style="{
      background: String.isNullOrWhiteSpace(element.linearGradient)
        ? ''
        : element.linearGradient + '!important',
      color: String.isNullOrWhiteSpace(element.fontColor)
        ? ''
        : element.fontColor + '!important',
    }"
  >
    <DevExpressGrid
      ref="grid"
      :gridId="gridId"
      :isLookupRelationTable="true"
      :element="element"
      :table="table"
    />
  </div>
  <template v-if="!isTableShow">
    <div
      class="w-100 bg-info h-40px d-flex align-items-center justify-content-between text-capitalize ps-2 fs-5 text-white cursor-pointer"
      :data-panelid="element.id"
      :data-isloadedonfirstrequest="table.isLoadedOnFirstRequest"
      :data-id="table.publicId"
      :data-customobjectid="table.customObjectPublicId"
      :data-formulaname="table.formulaName"
      :data-lookupobjectid="table.lookupObjectPublicId"
      :data-key="table.lookupObjectUrlName"
      :data-fieldid="table.fieldPublicId"
      :data-fieldformulaname="table.fieldFormulaName"
      :data-parentrecordid="$route.params.id"
      data-lookupval=""
      :data-pagecount="table.pageItemCount"
      :data-viewfilterid="table.viewFilterPublicId"
      :data-viewfilterformulaname="table.viewFilterFormulaName"
      v-if="isNewPage || isLoaded"
      @click="tableShowing"
      :style="{
        background: String.isNullOrWhiteSpace(element.linearGradient)
          ? ''
          : element.linearGradient + '!important',
        color: String.isNullOrWhiteSpace(element.fontColor)
          ? ''
          : element.fontColor + '!important',
      }"
    >
      {{ table.name }}
    </div>
    <div
      v-else
      :data-panelid="element.id"
      :data-isloadedonfirstrequest="table.isLoadedOnFirstRequest"
      :data-id="table.publicId"
      :data-customobjecid="table.customObjectPublicId"
      :data-lookupobjectid="table.lookupObjectPublicId"
      :data-key="table.lookupObjectUrlName"
      :data-formulaname="table.formulaName"
      :data-fieldid="table.fieldPublicId"
      :data-fieldformulaname="table.fieldFormulaName"
      :data-parentrecordid="$route.params.id"
      data-lookupval=""
      :data-pagecount="table.pageItemCount"
      :data-viewfilterid="table.viewFilterPublicId"
      :data-viewfilterformulaname="table.viewFilterFormulaName"
      class="w-100 bg-info h-40px d-flex lookup-relation-table align-items-center justify-content-start text-capitalize ps-2 fs-5 text-white cursor-pointer"
      @click="getTableRelationalRecords"
      :style="{
        background: String.isNullOrWhiteSpace(element.linearGradient)
          ? ''
          : element.linearGradient + '!important',
        color: String.isNullOrWhiteSpace(element.fontColor)
          ? ''
          : element.fontColor + '!important',
      }"
    >
      {{ table.name }}
      <i
        class="fas fa-sync fa-spin text-white fs-3 ms-3"
        :style="{
          color: String.isNullOrWhiteSpace(element.fontColor)
            ? ''
            : element.fontColor + '!important',
        }"
        v-if="spinShow"
      ></i>
      <i
        class="fas fa-ban text-white fs-3 ms-3"
        data-bs-toggle="tooltip"
        data-bs-html="true"
        data-bs-placement="top"
        :title="
          $t(
            'Components.PermissionNone.LookupRelationDescription',
            {},
            { locale: $store.state.activeLang }
          )
        "
        :style="{
          color: String.isNullOrWhiteSpace(element.fontColor)
            ? ''
            : element.fontColor + '!important',
        }"
        v-if="tableNoPermitted && !spinShow"
      ></i>
    </div>
  </template>
</template>
<script>
import $ from "jquery";
import { removeTooltips } from "@/core/helpers/dom";
export default {
  name: "Table",
  props: [
    "element",
    "table",
    "tableSortOptions",
    "buttons",
    "tableValues",
    "crudType",
  ],
  data() {
    return {
      isNewPage: this.$route.meta.isNewPage,
      isDetailPage: this.$route.meta.isDetailPage,
      isTableShow: false,
      gridId: `Table_${this.element.id}`,
      isLoaded: false,
      tableNoPermitted: false,
      spinShow: false,
    };
  },
  methods: {
    tableShowing() {
      this.isTableShow = true;
    },
    getTableRelationalRecords() {
      removeTooltips();

      if (this.tableNoPermitted) return;

      var panel = $(`[data-panelid='${this.element.id}']:not(.hidden)`),
        tabItem = panel.parents(".tab-pane"),
        tabItemIndex = tabItem.data("index");
      if (tabItemIndex > 0 && !panel.hasClass("sx-panel-draw-end")) {
        panel.addClass("sx-panel-draw-end");
        return;
      }

      this.$refs.grid.getData();
    },
  },
  mounted() {
    if (this.table.isLoadedOnFirstRequest) {
      this.getTableRelationalRecords();
    }
  },
};
</script>
