<template>
  <template v-if="button">
    <template v-if="!buttonHide">
      <label
        class="form-label fs-6 fw-bolder text-dark"
        for="element.id"
        v-if="this.labelShow"
        >&nbsp;</label
      >
      <button
        ref="button"
        type="button"
        class="btn btn-sx-custom-button w-100"
        :class="
          element.labelClassNames !== undefined
            ? element.labelClassNames
            : 'btn-light border'
        "
        :style="{
          background: element.linearGradient,
          color: element.fontColor,
        }"
        :id="element.id"
        :data-id="button.publicId"
        :data-name="button.name"
        :data-formulaname="button.formulaName"
        :data-customobjectid="button.customObjectPublicId"
        :data-buttontypename="button.buttonTypeName"
        :data-buttontypeid="button.buttonTypeId"
        :data-businessruleid="button.businessRulePublicId"
        :data-businessrulename="button.businessRuleName"
        :data-fieldid="button.fieldPublicId"
        :data-fieldname="button.fieldName"
        :data-staticlink="button.staticLink"
        :disabled="element.isDisabled"
        @click="buttonClick"
      >
        <span
          v-if="button.isIconViewEnabled"
          :style="{ color: element.fontColor }"
        >
          <i
            :class="button.icon"
            :style="{ color: button.iconColor }"
            v-if="button.iconColor"
          ></i>
          <i :class="button.icon" v-else></i>
        </span>
        {{ button.name }}
      </button>
    </template>
  </template>
</template>
<script>
import { showModal } from "@/core/helpers/dom";
import $ from "jquery";
export default {
  name: "Button",
  props: ["element", "button"],
  data() {
    return {
      labelShow: false,
      buttonHide: false,
    };
  },
  methods: {
    buttonClick() {
      var button = this.$refs.button;

      button.setAttribute("disabled", true);
      setTimeout(() => {
        button.removeAttribute("disabled");
      }, 3000);

      if (this.button.isShowApprovalMessage) {
        this.$root.buttonApproveOperation(this, this.button);
      } else {
        this.operation();
      }
    },
    operation() {
      var self = this,
        pageData = this.$parent.pageData,
        values = pageData.responseValues,
        fields = pageData.fields.find((f) => f.isMainObject).fieldModels,
        button = this.$refs.button,
        customObjectKey = this.$route.params.key,
        recordPublicId = this.$route.params.id,
        buttonFieldPublicId = this.button.fieldPublicId;
      switch (this.button.buttonTypeId) {
        case 1:
          this.$root.businessRuleButtonTrigger(
            this.button,
            customObjectKey,
            recordPublicId
          );
          break;
        case 2:
          this.$root.directForwardButtonTrigger(
            this.button,
            customObjectKey,
            recordPublicId
          );
          break;
        case 3:
          var currentField = fields.find(
              (f) => f.publicId == buttonFieldPublicId
            ),
            isNewPage = this.$route.meta.isNewPage,
            isEditPage = this.$route.meta.isEditPage,
            calculateFormulaRequest = {
              buttonPublicId: this.button.publicId,
              customObjectKey: this.$route.params.key,
              recordPublicId: this.$route.params.id,
            };

          if (isNewPage || isEditPage) {
            var pageItem = $(
              `.page-item[data-publicid="${buttonFieldPublicId}"]`
            );

            if (!currentField) {
              return;
            }

            var formId = isNewPage ? "#newForm" : "#editForm",
              setModel = this.$root.modelCreate(formId);

            calculateFormulaRequest = {
              ...calculateFormulaRequest,
              recordValues: setModel.values.map((m) => {
                return { key: m.key, value: m.value };
              }),
            };

            if (pageItem && pageItem.length > 0) {
              var valueObj = this.$root.getPageItemFormValue(pageItem);
              this.$root.customForwardButtonTrigger(
                this.button,
                currentField,
                valueObj.value,
                customObjectKey,
                recordPublicId,
                calculateFormulaRequest
              );
            } else {
              var requestDataObj = {
                  publicId: currentField.publicId,
                  fieldsValues: [],
                },
                requestModel = {
                  customObjectPublicId: setModel.customObjectPublicId,
                  data: null,
                  pageLayoutFormulaName: setModel.layout.formulaName,
                };

              if (!setModel) {
                return;
              }

              setModel.values.forEach((valueObj) => {
                requestDataObj.fieldsValues.push({
                  key: valueObj.key,
                  value: valueObj.value,
                });
              });

              requestModel.data = JSON.stringify(requestDataObj);

              this.$root
                .calculateFieldDefaultValue(requestModel)
                .then((response) => {
                  var result = response.data,
                    value = result.value;
                  if (result.isOk) {
                    this.$root.customForwardButtonTrigger(
                      this.button,
                      currentField,
                      value,
                      customObjectKey,
                      recordPublicId,
                      calculateFormulaRequest
                    );
                  }
                })
                .catch(function (error) {
                  //TODO:Error
                  // alert(error);
                });
            }
          } else {
            if (this.button.sendDefaultValueWithButton) {
              var recordValues = this.$root.gridDataChange(
                [pageData.responseValues],
                fields,
                false
              );

              var requestRecordValues = [];
              if (recordValues.length > 0) {
                recordValues.forEach((recordValue) => {
                  Object.keys(recordValue)
                    .filter(
                      (f) =>
                        f !== "PUBLICID" &&
                        !f.includes(self.$fieldPublicIdFormulaExtension)
                    )
                    .forEach((formulaName) => {
                      var value = recordValue[formulaName],
                        publicIdValue =
                          recordValue[
                            formulaName + self.$fieldPublicIdFormulaExtension
                          ];

                      if (publicIdValue) {
                        value = publicIdValue;
                      }

                      requestRecordValues.push({
                        key: formulaName,
                        value: value,
                      });
                    });
                });

                calculateFormulaRequest = {
                  ...calculateFormulaRequest,
                  recordValues: requestRecordValues,
                };
              }
            }

            if (String.isNullOrWhiteSpace(currentField)) {
              currentField = {
                publicId: buttonFieldPublicId,
                formulaName: this.button.fieldFormulaName,
              };
            }

            var fieldFormula = currentField.formulaName;

            valueObj = values[fieldFormula];

            if (String.isNullOrWhiteSpace(valueObj)) {
              var reqModel = {
                customObjectKey: this.$route.params.key,
                recordPublicId: this.$route.params.id,
                formulaNames: [fieldFormula],
              };
              this.$root.customForwardButtonTriggerNoPageDataValue(
                this.button,
                currentField,
                reqModel,
                this.$route.params.key,
                this.$route.params.id,
                calculateFormulaRequest
              );
            } else {
              this.$root.customForwardButtonTrigger(
                this.button,
                currentField,
                valueObj.value,
                this.$route.params.key,
                this.$route.params.id,
                calculateFormulaRequest
              );
            }
          }
          break;
        case 4:
          var recordDetail = this.$root.gridDataChange([values], fields, false);
          this.$root.formTemplateButtonTrigger(
            this.button,
            this.$route.params.key,
            this.$route.params.id,
            recordDetail[0],
            fields
          );
          break;
        case 5:
          this.$root.businessRuleButtonTrigger(
            this.button,
            customObjectKey,
            recordPublicId
          );
          showModal(document.getElementById("buttonModal"));
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    if (!this.button) return;

    this.buttonHide = false; //this.$route.meta.isNewPage;
    if (this.buttonHide) {
      return;
    }

    this.labelShow =
      $(this.$refs.button).closest(".row").find("input, textarea, select")
        .length > 0
        ? true
        : false;
  },
};
</script>
